<template>
  <div id="home-static" >
    <div id="step0">
      <div class="container" >

           <router-link to="/calipers" class="desktop">
            <img :src="homeImages[3]" class="home-animation" alt="CarboBrake Calipers"/>
          </router-link>

          <router-link to="/calipers-txx" class="desktop">
            <img :src="homeImages[4]" class="home-animation" alt="CarboBrake Calipers TXX"/>
          </router-link> 

          <router-link to="/projects" class="desktop">
            <img :src="homeImages[1]" class="home-animation" alt="CarboBrake Projects"/>
          </router-link>

          <router-link to="/calipers"  class="desktop" >
            <img :src="homeImages[5]" class="home-animation" alt="CarboBrake Calipers"/>
          </router-link>

          <a href="/shop" class="desktop">
            <img :src="homeImages[2]" class="home-animation" alt="CarboBrake Shop"/>
          </a>

          <router-link to="/mission-vision" class="desktop">
            <img :src="homeImages[0]" class="home-animation" alt="CarboBrake Mission"/>
          </router-link>

     

        <router-link to="/calipers"  class="mobile">
          <img :src="homeImages[10]" class="home-animation" alt="CarboBrake Calipers"/>
        </router-link>

        <router-link to="/calipers-txx" class="mobile" >
          <img :src="homeImages[11]" class="home-animation" alt="CarboBrake Calipers TXX"/>
        </router-link> 

        <router-link to="/projects" class="mobile">
          <img :src="homeImages[6]" class="home-animation" alt="CarboBrake Projects"/>
        </router-link>

        <router-link to="/calipers" class="mobile">
          <img :src="homeImages[7]" class="home-animation" alt="CarboBrake Calipers"/>
        </router-link>

        <a href="/shop" class="mobile">
          <img :src="homeImages[8]" class="home-animation" alt="CarboBrake Shop"/>
        </a>

        <router-link to="/mission-vision" class="mobile">
          <img :src="homeImages[9]" class="home-animation" alt="CarboBrake Mission"/>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import { TweenMax, Power3 } from "gsap";

export default {
  name: "HomepageMenu",
  data() {
    return {
      homeImages: [
        require("../assets/Company_home.png"),
        require("../assets/Projects_home.png"),
        require("../assets/Shop_home.png"),
        require("../assets/AXX_Calipers_home.png"),
        require("../assets/TXX_Calipers_home.png"),
        require("../assets/Products_home.png"),
        require("../assets/Projects_home_M.jpg"),
        require("../assets/Products_home_M.jpg"),
        require("../assets/Shop_home_M.jpg"),
        require("../assets/Company_home_M.jpg"),
        require("../assets/AXX_Calipers_home_M.jpg"),
        require("../assets/TXX_Calipers_home_M.jpg"),
      ],
    }
  },
  methods:{
    imageLoad () {
      let step0 = document.getElementsByClassName("home-animation");

      TweenMax.fromTo(
          step0,
          1.5,
          {
            display: "none",
            opacity: 0,
            scale: 0.8,
          },
          {
            display: "inline",
            opacity: 1,
            scale: 1,
            ease: Power3.easeIn,
          }
      );
    }
  },
  mounted() {
    if (this.$cookie.get('animation')!== "yes") {
      setTimeout(() =>{
        this.imageLoad();
      },2350)
    } else {
      this.imageLoad();
    }
    }
}
</script>

<style scoped>

</style>