<template>
  <div id="instagram" style="width: 80%;margin: auto">

    <h3>CarboBrake around the world</h3>

    <carousel-3d :disable3d="true" :space="350" :clickable="false" :controls-visible="true" :width="300" :height="600" :autoplay="true" :autoplayHoverPause="true">
      <slide v-for="(slide, i) in posts.data" :index="i" :key="i">
        <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
          <div>
            <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="slide.media_url" alt="CarboBrake Instagram">
            <p>{{slide.caption}}</p>
            <a class="learn-more" :href="slide.permalink" style="text-decoration: none;" target="_blank" rel="nofollow">Discover more...</a>
          </div>
        </template>
      </slide>
    </carousel-3d>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import axios from "axios";

export default {
  name: "Instagram",
  data (){
    return {
      posts : [],
    }
  },
  components : {
    Carousel3d,
    Slide
  },
  methods : {
    getInstagramPosts() {
      let url = 'https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,username&access_token=IGQVJVWHlFRXkzMEFkVl9nZAUd3SDhreVJ6UldoWjVBekl6ZAmJHUzBvUllmdkNkbWxHcGtCcXpYX2ctb1ZAZAd0dDQ0l5OGEyUDVTbWpSZAGw5RktCa0JLaEJaOXQyOFZAUdkd2ajhZATTl3'
      axios.get(url)
          .then(res => {
            /*console.log(res.data);*/
            this.posts = res.data;
            /*console.log(this.posts);*/
          })
          .catch(err => console.log(err));
    },
  },
  mounted() {
    this.getInstagramPosts();
  }
};
</script>

<style scoped>
.carousel-3d-container {
  margin: 100px 100px 0 0;
}
.carousel-3d-slide{
  background-color: transparent;
  border-style: none;
}
.prev {
  color: #2756ff;
  line-height: 0;
}
img{
  width: 300px;
  height: 360px;
  object-fit: cover;
}
h3{
  font-size: 26px;
  font-family: Anita, sans-serif;
  font-weight: 700;
  color: #2756ff;
  margin-bottom: 50px;
}
</style>